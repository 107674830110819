<template>
  <div>
    <div class="mb-4 flex items-center justify-between">
      <h2>Artikelübersicht</h2>
      <router-link
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2"
        to="/auftrag/neu"
      >
        Neuer Artikel
      </router-link>
    </div>

    <dashboard-element>
      <dashboard-head title="Artikel" />
      <dashboard-table class="p-3">
        <div v-for="art in artikel" :key="art.id">
          <dashboard-card
            :content="art.bezeichnung"
            :link="`/artikel/${art.artikelcode}`"
            :title="art.artikelcode"
          ></dashboard-card>
        </div>
      </dashboard-table>
    </dashboard-element>
  </div>
</template>

<script>
import DashboardHead from "../../components/dashboard/DashboardHead.vue";
import DashboardTable from "../../components/dashboard/DashboardTable.vue";
import DashboardCard from "../../components/dashboard/DashboardCard.vue";
import DashboardElement from "../../components/dashboard/DashboardElement.vue";
import { supabase } from "../../plugins/supabase";

export default {
  components: {
    DashboardHead,
    DashboardTable,
    DashboardCard,
    DashboardElement
  },
  name: "ArtikelÜbersicht",
  data() {
    return {
      artikel: []
    };
  },
  mounted() {
    this.loadInitialData();

    supabase
      .from("artikel")
      .on("*", payload => {
        console.log("Change received!", payload);
        this.loadInitialData();
      })
      .subscribe();
  },
  methods: {
    createProduct() {
      console.log("NEW PRODUCT NOW PLZZZ");
    },
    async loadInitialData() {
      const { data: artikel, error } = await supabase
        .from("artikel")
        .select("*")
        .range(0, 9);

      error && console.log(error);

      this.artikel = artikel;
    }
  }
};
</script>
